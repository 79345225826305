import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header';
import { PlayCircleOutline as PlayIcon, PauseCircleOutline as PauseIcon } from '@mui/icons-material';
import { 
  Box, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Typography, 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from '@mui/material';
import { useCreateAlertMutation } from 'state/api';

const AddAlert = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [alertCycle, setAlertCycle] = useState(1);
  const [lang, setLang] = useState('Francais');
  const [audio, setAudio] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [open, setOpen] = useState(false);
  const [createAlert] = useCreateAlertMutation();

  const handleAlertCycleChange = (event) => {
    setAlertCycle(event.target.value);
  };

  const handleLangChange = (event) => {
    setLang(event.target.value);
  };

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'audio/wav') {
      setAudioFile(file);
      setAudio(new Audio(URL.createObjectURL(file)));
    } else {
      alert("Veuillez télécharger un fichier audio au format .wav");
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/liste des alertes');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title) {
      alert("Le titre de l'alerte est obligatoire");
      return;
    }
    if (!description) {
      alert("La description de l'alerte est obligatoire");
      return;
    }
    if (!startDate) {
      alert("La date de début est obligatoire");
      return;
    }
    if (!startTime) {
      alert("L'heure de début est obligatoire");
      return;
    }
    if (!audioFile) {
      alert("Veuillez télécharger un fichier audio");
      return;
    }
    
    const formData = new FormData();
    formData.append('topic', title);
    formData.append('description', description);
    formData.append('startdate', startDate);
    formData.append('starttime', startTime);
    formData.append('alertcycle', alertCycle);
    formData.append('lang', lang);
    formData.append('audio_new', audioFile);

    try {
      const response = await createAlert(formData).unwrap();
      if (response.Status === "Ok") {
        handleOpen();
      } else {
        alert("Erreur lors de la création de l'alerte. Veuillez réessayer.");
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'alerte:', error);
      console.log('Données envoyées:', {
        title,
        description,
        startDate,
        startTime,
        alertCycle,
        lang,
        audioFile,
      });
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="AJOUTER UNE NOUVELLE ALERTE" subtitle="Créez une nouvelle alerte vocale à diffuser"/>
      <Box mt="1.5rem" />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Titre de l'alerte" 
              variant="outlined" 
              value={title} 
              onChange={e => setTitle(e.target.value)} 
              required 
              aria-label="Titre de l'alerte"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Description de l'alerte" 
              variant="outlined" 
              multiline 
              rows={4} 
              value={description} 
              onChange={e => setDescription(e.target.value)} 
              required 
              aria-label="Description de l'alerte"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              fullWidth 
              label="Date de début" 
              type="date" 
              InputLabelProps={{ shrink: true }} 
              variant="outlined" 
              value={startDate} 
              onChange={e => setStartDate(e.target.value)} 
              required 
              aria-label="Date de début"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              fullWidth 
              label="Heure de début" 
              type="time" 
              InputLabelProps={{ shrink: true }} 
              variant="outlined" 
              value={startTime} 
              onChange={e => setStartTime(e.target.value)} 
              required 
              aria-label="Heure de début"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Répétition de l'audio</InputLabel>
              <Select 
                value={alertCycle} 
                onChange={handleAlertCycleChange} 
                label="Répétition de l'audio" 
                aria-label="Répétition de l'audio"
              >
                <MenuItem value={1}>Une fois</MenuItem>
                <MenuItem value={2}>Deux fois</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Langue de l'alerte</InputLabel>
              <Select 
                value={lang} 
                onChange={handleLangChange} 
                label="Langue de l'alerte" 
                aria-label="Langue de l'alerte"
              >
                <MenuItem value="Francais">Français</MenuItem>
                <MenuItem value="Anglais">Anglais</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Button 
                variant="contained" 
                component="label" 
                aria-label="Télécharger l'audio"
              >
                Télécharger l'audio
                <input 
                  type="file" 
                  hidden 
                  accept=".wav" 
                  onChange={handleAudioChange} 
                />
              </Button>
              {audioFile && (
                <Box display="flex" alignItems="center" ml={2}>
                  <IconButton onClick={handlePlayPause} aria-label="Prévisualiser l'audio">
                    {isPlaying ? <PauseIcon /> : <PlayIcon />}
                  </IconButton>
                  <Typography variant="body1" ml={1}>Prévisualiser l'audio</Typography>
                  <audio src={audioFile} style={{ display: 'none' }} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              type="submit" 
              aria-label="Créer une alerte"
            >
              Créer une alerte
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Nouvelle alerte créée"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Votre alerte a été créée avec succès !</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" aria-label="Fermer">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddAlert;
