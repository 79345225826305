import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material";
import { themeSettings } from "theme";
import { useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import AlertList from "scenes/alertlist";
import AddAlert from "scenes/addalert";
import UsersList from "scenes/userlist";
import AddUser from "scenes/adduser";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return <div className="app">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<Layout />} >
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/liste des contacts" element={<UsersList />} />
            <Route path="/liste des alertes" element={<AlertList />} />
            <Route path="/Création d'alerte" element={<AddAlert />} />
            <Route path="/Nouveau contact" element={<AddUser />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </div>
}

export default App;
