import React, { useState } from 'react';
import data from './data.json';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@mui/material';

const FormPerson = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [sexe, setSexe] = useState('');
  const [anneeNaissance, setAnneeNaissance] = useState('');
  const [profession, setProfession] = useState('');
  const [carteIdentite, setCarteIdentite] = useState('');
  const [telephones, setTelephones] = useState(['']);
  const [region, setRegion] = useState('');
  const [departement, setDepartement] = useState('');
  const [commune, setCommune] = useState('');
  const [village, setVillage] = useState('');
  const [communication, setCommunication] = useState('');
  const [radio, setRadio] = useState('');
  const [langue, setLangue] = useState('');

  const handlePhoneChange = (index, value) => {
    const updatedPhones = [...telephones];
    updatedPhones[index] = value;
    setTelephones(updatedPhones);
  };

  const handleAddPhone = () => {
    setTelephones([...telephones, '']);
  };

  const handleRemovePhone = (index) => {
    const updatedPhones = [...telephones];
    updatedPhones.splice(index, 1);
    setTelephones(updatedPhones);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField fullWidth label="Nom *" variant="outlined" value={nom} onChange={e => setNom(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="Prénom" variant="outlined" value={prenom} onChange={e => setPrenom(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Sexe</InputLabel>
          <Select value={sexe} onChange={e => setSexe(e.target.value)}>
            <MenuItem value=""><em>Inconnu</em></MenuItem>
            <MenuItem value="homme">Homme</MenuItem>
            <MenuItem value="femme">Femme</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="Année de Naissance" variant="outlined" value={anneeNaissance} onChange={e => setAnneeNaissance(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="Profession" variant="outlined" value={profession} onChange={e => setProfession(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="Numéro de Carte d'identité" variant="outlined" value={carteIdentite} onChange={e => setCarteIdentite(e.target.value)} />
      </Grid>
      {telephones.map((phone, index) => (
        <Grid item xs={12} key={index}>
          <TextField
            fullWidth
            label={`Téléphone ${index + 1} *`}
            variant="outlined"
            value={phone}
            onChange={e => handlePhoneChange(index, e.target.value)}
          />
          {index === telephones.length - 1 && (
            <Typography variant="body2" color="textSecondary">
              ATTENTION: Format accepté pour le numéro de téléphone: 221xxxxxxxxx/220xxxxxxx/223xxxxxxxx/227xxxxxxxx
            </Typography>
          )}
          {index === telephones.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '10px' }}
              onClick={handleAddPhone}
            >
              Ajouter un autre numéro
            </Button>
          )}
          {index !== 0 && (
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginTop: '10px', marginLeft: '10px' }}
              onClick={() => handleRemovePhone(index)}
            >
              Supprimer
            </Button>
          )}
        </Grid>
      ))}
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Région *</InputLabel>
          <Select value={region} onChange={e => setRegion(e.target.value)}>
            <MenuItem value=""><em>Inconnue</em></MenuItem>
            {Object.keys(data.regions).map((region) => (
              <MenuItem key={region} value={region}>{region}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {region && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Département *</InputLabel>
            <Select value={departement} onChange={e => setDepartement(e.target.value)}>
              <MenuItem value=""><em>Sélectionnez un Département</em></MenuItem>
              {data.regions[region].map((dept) => (
                <MenuItem key={dept} value={dept}>{dept}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {departement && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Commune *</InputLabel>
            <Select value={commune} onChange={e => setCommune(e.target.value)}>
              <MenuItem value=""><em>Sélectionnez une Commune</em></MenuItem>
              {data.departements[departement].map((com) => (
                <MenuItem key={com} value={com}>{com}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField fullWidth label="Village" variant="outlined" value={village} onChange={e => setVillage(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Méthode de communication préférée</InputLabel>
          <Select value={communication} onChange={e => setCommunication(e.target.value)}>
            <MenuItem value=""><em>Inconnu</em></MenuItem>
            <MenuItem value="vocal">Vocal</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
            <MenuItem value="indifferent">Indifférent</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Station Radio préférée</InputLabel>
          <Select value={radio} onChange={e => setRadio(e.target.value)}>
            <MenuItem value=""><em>Inconnu</em></MenuItem>
            <MenuItem value="rfm">RFM</MenuItem>
            <MenuItem value="zik fm">Zik FM</MenuItem>
            <MenuItem value="autre">Autre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Langue</InputLabel>
          <Select value={langue} onChange={e => setLangue(e.target.value)}>
            <MenuItem value="Wolof">Wolof</MenuItem>
            <MenuItem value="Français">Français</MenuItem>
            <MenuItem value="Autre">Autre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormPerson;

