import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL
    }),
    reducerPath: "userApi",
    tagTypes: ["Users", "Alert"],
    endpoints: (build) => ({
        getUser: build.query({
            query: () => `users/view`,
            providesTags: ["User"],
        }),
        getUsers: build.query({
            query: () => `users`,
            providesTags: ["Users"],
        }),
        getAlerts: build.query({
            query: () => `alerts/4`,
            providesTags: ["Alert"],
        }),
        createAlert: build.mutation({
            query: (alertData) => ({
                url: 'alerts/add',
                method: 'POST',
                body: alertData,
            }),
            invalidatesTags: ["addAlert"],
        })
    }),
})

export const { useGetUserQuery, useGetUsersQuery, useGetAlertsQuery, useCreateAlertMutation } = api;