import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Checkbox, FormControlLabel, Grid, MenuItem, Select, InputLabel } from '@mui/material';

const FormCSV = ({ onClose }) => {
  const [isIntro, setIsIntro] = useState(true); // État pour gérer l'affichage de l'introduction

  const handleImportClick = () => {
    setIsIntro(false); // Passe à l'affichage du formulaire complet
  };

  return (
    <Box p="2rem">
      {isIntro ? (
        <Box>
          <Typography variant="h6">Importer par CSV</Typography>
          <Typography variant="body1" paragraph>
            Ce formulaire vous permet d'importer un ensemble d'utilisateurs à partir d'un fichier CSV.
          </Typography>
          <Typography variant="body2" paragraph>
            Dans le champ format, vous pouvez donner le format du fichier CSV sous la forme de la liste des champs séparés par ":::". Les mots clés supportés sont :
          </Typography>
          <ul>
            <li>ignore: cet élément dans le fichier ne sera pas considéré</li>
            <li>id: Si un champ id est fourni, le système recherchera un utilisateur avec cet id, et mettra à jour les champs. La ligne sera ignorée si l'utilisateur n'existe pas ou si le champ n'est pas rempli.</li>
            <li>type: Type de profil : 1- Personne, 2- Groupement, 3- Entreprise</li>
            <li>phone: Numéro de téléphone avec un des formats suivants: 221 + 9 chiffres (Sénégal) ou 220 + 7 chiffres (Gambie)</li>
            <li>name: Nom de la personne/du Groupement/de l'Entreprise</li>
            <li>firstname: Prénom (personne uniquement)</li>
            <li>birthdate: Année de naissance de la personne/Année de création du groupement/de l'entreprise</li>
            <li>gender: Genre de la personne</li>
            <li>region: Région de la personne</li>
            <li>department: Département de la personne</li>
            <li>arrondissement: Commune de la personne</li>
            <li>town: Ville/adresse de la personne/du siège du groupement ou de l'entreprise</li>
            <li>language: Langue</li>
            <li>nationalid: Numéro de carte d'identité de la personne/NINEA de l'entreprise</li>
            <li>job: Métier de la personne/Objet du Groupement ou de l'Entreprise</li>
            <li>secteur: Secteur du Groupement ou de l'Entreprise</li>
            <li>addresse_rencontre: Adresse des rencontres du groupement</li>
            <li>size: Nombre de membres du groupement/nombre d'employés de l'entreprise</li>
            <li>wsize: Nombre de femmes dans le groupement</li>
            <li>head: Président du Groupement ou Directeur de l'Entreprise</li>
            <li>finance: Trésorier du Groupement ou Directeur financier de l'Entreprise</li>
            <li>secretariat: Secrétaire du Groupement</li>
            <li>meeting: Fréquence des rencontres du Groupement</li>
            <li>form: Type d'entreprise: 1- Entreprise individuelle, 2- SARL, 3- SA, 4- Société Unipersonnelle à responsabilité limitée (SURL), 5- GIE (Groupement d'intérêt économique), 6- Société de nom collectif (SNC), 7- Société en Commandite Simple (SCS), 8- Société Civile, 9- Société Coopérative</li>
            <li>capital: Capital social de l'Entreprise en FCFA</li>
            <li>description: Description/Commentaire</li>
          </ul>
          <Button variant="contained" color="primary" onClick={handleImportClick}>
            Importer par CSV
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6">Importer des utilisateurs par CSV</Typography>
          <input type="file" accept=".csv" />
          <TextField
            fullWidth
            label="Format du fichier"
            placeholder="ignore:::type:::name:::ignore:::phone"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Séparateur de champs"
            placeholder=";"
            variant="outlined"
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox color="secondary" />}
            label="Vérifier le fichier sans ajouter les utilisateurs"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel id="profile-type-label">Type de profil</InputLabel>
              <Select
                labelId="profile-type-label"
                fullWidth
                variant="outlined"
                defaultValue=""
              >
                <MenuItem value="">Pas de valeur globale pour le type de profil</MenuItem>
                {/* Ajoutez les autres options de type de profil ici */}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel id="language-label">Langue</InputLabel>
              <Select
                labelId="language-label"
                fullWidth
                variant="outlined"
                defaultValue="Wolof"
              >
                <MenuItem value="Wolof">Wolof</MenuItem>
                {/* Ajoutez les autres langues disponibles ici */}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Racine du Nom"
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Métier/Objet"
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Région (*)"
                variant="outlined"
                margin="normal"
                defaultValue="Inconnue"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Département (*)"
                variant="outlined"
                margin="normal"
                defaultValue="Sélectionnez une Région"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Commune"
                variant="outlined"
                margin="normal"
                defaultValue="Sélectionnez un Département"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" style={{ marginRight: '1rem' }}>
                Importer
              </Button>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FormCSV;
