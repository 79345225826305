import React, { useState } from 'react';
import { Grid,TextField, MenuItem, Select, FormControl, InputLabel, Button, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import data from './data.json';

const FormEntreprise = () => {
  const [region, setRegion] = useState('');
  const [departement, setDepartement] = useState('');
  const [commune, setCommune] = useState('');
  const [phones, setPhones] = useState(['']);

  const handlePhoneChange = (index, event) => {
    const newPhones = [...phones];
    newPhones[index] = event.target.value;
    setPhones(newPhones);
  };

  const handleAddPhone = () => {
    setPhones([...phones, '']);
  };

  const handleRemovePhone = (index) => {
    const newPhones = [...phones];
    newPhones.splice(index, 1);
    setPhones(newPhones);
  };

  return (
    
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nom (*)"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Année de Création"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Objet"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="NINEA"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Type d'entreprise *</InputLabel>
            <Select label="Type d'entreprise *">
              <MenuItem value="sarl">SARL</MenuItem>
              <MenuItem value="sa">SA</MenuItem>
              <MenuItem value="eurl">EURL</MenuItem>
              <MenuItem value="autre">Autre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Capital social (FCFA)"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Secteur"
            variant="outlined"
          />
        </Grid>
        {phones.map((phone, index) => (
          <Grid item xs={12} container alignItems="flex-end" key={index}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`Téléphone ${index === 0 ? "(*)" : ""}`}
                variant="outlined"
                value={phone}
                onChange={event => handlePhoneChange(index, event)}
                required={index === 0}
                helperText={index === 0 ? "Format accepté: 221xxxxxxxxx/220xxxxxxx/223xxxxxxxx/227xxxxxxxx" : ""}
              />
            </Grid>
            <Grid item xs={2}>
              {index > 0 && (
                <IconButton onClick={() => handleRemovePhone(index)}>
                  <Delete />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAddPhone}>
            Ajouter un numéro
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre d'employés"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Région *</InputLabel>
            <Select value={region} onChange={e => setRegion(e.target.value)}>
              <MenuItem value=""><em>Inconnue</em></MenuItem>
              {Object.keys(data.regions).map((region) => (
                <MenuItem key={region} value={region}>{region}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {region && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Département *</InputLabel>
              <Select value={departement} onChange={e => setDepartement(e.target.value)}>
                <MenuItem value=""><em>Sélectionnez un Département</em></MenuItem>
                {data.regions[region].map((dept) => (
                  <MenuItem key={dept} value={dept}>{dept}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {departement && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Commune *</InputLabel>
              <Select value={commune} onChange={e => setCommune(e.target.value)}>
                <MenuItem value=""><em>Sélectionnez une Commune</em></MenuItem>
                {data.departements[departement].map((com) => (
                  <MenuItem key={com} value={com}>{com}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Adresse du siège"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Directeur Général"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Responsable Financier"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Langue Préférée</InputLabel>
            <Select label="Langue Préférée">
              <MenuItem value="wolof">Wolof</MenuItem>
              <MenuItem value="francais">Français</MenuItem>
              <MenuItem value="anglais">Anglais</MenuItem>
              <MenuItem value="autre">Autre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
  );
};

export default FormEntreprise;
