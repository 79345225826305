// src/components/FormDocument.jsx

import React from 'react';
import { Box, Button, TextField, Grid, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const FormDocument = ({ onDelete }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Titre"
            variant="outlined"
            helperText="Caractères autorisés: a-zA-Z0-9,.-_"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            helperText="Caractères autorisés: a-zA-Z0-9,.-_"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Type</InputLabel>
            <Select>
              <MenuItem value="">Sélectionner un type</MenuItem>
              {/* Ajoutez ici les options de type si nécessaire */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Organisation</InputLabel>
            <Select>
              <MenuItem value="">Sélectionner une organisation</MenuItem>
              {/* Ajoutez ici les options d'organisation si nécessaire */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Fichier</Typography>
          <Button variant="contained" component="label">
            Choisir un fichier
            <input type="file" hidden accept=".pdf,.png,.doc,.docx,.xls,.xlsx" />
          </Button>
          <Typography variant="body2">Fichiers supportés: pdf, png, doc, docx, xls, xlsx</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={onDelete}>
            Supprimer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormDocument;
