import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header';
import FormPerson from 'components/FormPerson';
import FormEntreprise from 'components/FormEntreprise';
import FormGroupement from 'components/FormGroupement';
import FormDocument from 'components/FormDocument';
import FormCSV from 'components/FormCSV'; // Import du composant FormCSV
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const AddUser = () => {
  const navigate = useNavigate();
  const [profileType, setProfileType] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [statusChecked, setStatusChecked] = useState(false);
  const [alertChecked, setAlertChecked] = useState(false);
  const [banChecked, setBanChecked] = useState(false);
  const [infoRequestChecked, setInfoRequestChecked] = useState(false);
  const [formDocuments, setFormDocuments] = useState([]);
  const [csvImportOpen, setCSVImportOpen] = useState(false); // Etat pour gérer l'ouverture du formulaire CSV

  const handleProfileTypeChange = (event) => {
    setProfileType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('POST', { profileType });
    navigate('/liste des utilisateurs');
  };

  const handleCancel = () => {
    setConfirmOpen(true);
  };

  const handleConfirmCancel = () => {
    setProfileType('');
    setFormDocuments([]);
    setConfirmOpen(false);
  };

  const handleCloseCancel = () => {
    setConfirmOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatusChecked(event.target.checked);
  };

  const handleAlertChange = (event) => {
    setAlertChecked(event.target.checked);
  };

  const handleBanChange = (event) => {
    setBanChecked(event.target.checked);
  };

  const handleInfoRequestChange = (event) => {
    setInfoRequestChecked(event.target.checked);
  };

  const addFormDocument = () => {
    setFormDocuments([...formDocuments, { id: Date.now() }]);
  };

  const deleteFormDocument = (id) => {
    setFormDocuments(formDocuments.filter((doc) => doc.id !== id));
  };

  const handleCSVImportOpen = () => {
    setCSVImportOpen(true);
  };

  const handleCSVImportClose = () => {
    setCSVImportOpen(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="AJOUTER UN NOUVEL UTILISATEUR" subtitle="Créez un nouveau profil utilisateur" />
      <Box mt="1.5rem" />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1">Choisissez votre type de profil</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Type de profil *</InputLabel>
                <Select value={profileType} onChange={handleProfileTypeChange} label="Type de profil *">
                  <MenuItem value="">Sélectionner le type de profil</MenuItem>
                  <MenuItem value="personne">Personne</MenuItem>
                  <MenuItem value="entreprise">Entreprise</MenuItem>
                  <MenuItem value="groupement">Groupement</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box display="flex" justifyContent="flex-end">
                {profileType === '' && (
                  <Button variant="contained" color="secondary" onClick={handleCSVImportOpen}>
                    Importer par CSV
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt="1.5rem" />
          </Grid>
          {profileType === 'personne' && <FormPerson />}
          {profileType === 'entreprise' && <FormEntreprise />}
          {profileType === 'groupement' && <FormGroupement />}
          
          {profileType && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={statusChecked}
                      onChange={handleStatusChange}
                      color="secondary"
                    />
                  }
                  label={
                    <Typography>
                      <strong>Status</strong> (Le profil fait partie du carnet d'adresse)
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={alertChecked}
                      onChange={handleAlertChange}
                      color="secondary"
                    />
                  }
                  label="Ce profil ne reçoit pas d'alerte"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Commentaire"
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt="1.5rem" />
                <Typography variant="body1" fontWeight="bold">Documents</Typography>
                {formDocuments.map((doc) => (
                  <FormDocument key={doc.id} onDelete={() => deleteFormDocument(doc.id)} />
                ))}
                <Box mt="1.5rem" />
                <Button variant="contained" color="primary" onClick={addFormDocument}>
                  Attacher un nouveau document au profil
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box mt="1.5rem" />
                <Typography variant="body1" fontWeight="bold">Banissement</Typography>
                <Box mt="1.5rem" />
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={banChecked}
                      onChange={handleBanChange}
                      color="secondary"
                    />
                  }
                  label="Ce profil peut actuellement utiliser le service. Pour le bannir, cocher la case à cocher."
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">Demande d'enregistrement</Typography>
                <Box mt="1.5rem" />
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={infoRequestChecked}
                      onChange={handleInfoRequestChange}
                      color="secondary"
                    />
                  }
                  label="Demander des informations détaillées : (l'utilisateur devra fournir les informations détaillées du profil lors de sa prochaine visite. Décocher la case si vous avez toutes les informations nécessaires.)"
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt="1.5rem" />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button type="submit" variant="contained" color="secondary" style={{ marginRight: '1rem' }}>
                  Soumettre
                </Button>
                <Button variant="contained" color="primary" onClick={handleCancel}>
                  Annuler
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      <Dialog open={confirmOpen} onClose={handleCloseCancel}>
        <DialogTitle>Confirmer l'annulation</DialogTitle>
        <DialogContent>
          <DialogContentText>Êtes-vous sûr de vouloir annuler? Toutes les données non sauvegardées seront perdues.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel} color="primary">Non</Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>Oui</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={csvImportOpen} onClose={handleCSVImportClose} maxWidth="md" fullWidth>
        <DialogTitle>Importer par CSV</DialogTitle>
        <DialogContent>
          <FormCSV onClose={handleCSVImportClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCSVImportClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddUser;
